<template>
  <div id="Portrait">
    <van-nav-bar :fixed="true" placeholder :title="$t('My.PersonalInformation.portrait.SetAvatar')" left-arrow
      @click-left="onClickLeft" />
    <!-- <van-uploader v-model="imgFile" :result-type="SubmitEvent"></van-uploader> -->
    <div class="header">
      <div class="upload_wrap">
        <van-image round width="3.75rem" height="3.75rem" :src="$img(DataArr.url)" />
        <input type="file" @input="upload" />
      </div>
      <span class="button" @click="SubmitEvent()">{{
        $t('My.PersonalInformation.portrait.button')
      }}</span>
    </div>
    <div class="header-One"></div>
    <div class="content" >
      <!-- <div class="content-item" v-for="(item, index) in ImgArr" :key="index">
        <van-image round width="100%" height="100%" @click="ImgEvent(item.url)" :src="item.url" />
      </div> -->

      <!-- 后面改的 -->
      <!-- <div class="content-item" v-for="(item, index) in 20" :key="index">
        <van-image round width="100%" height="100%"
          @click="ImgEvent('/uploads/system/userimg/userimg_' + (index + 1) + '.png')"
          :src="$img('/uploads/system/userimg/userimg_' + (index + 1) + '.png')" />
      </div> -->
    </div>
  </div>
</template>

<script>
import ImgData from '@/utils/ImgData' // 封装的头像数据
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import { getUserInfoNew } from '@/api'
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Toast,
  Uploader
} from 'vant'
import { mapGetters } from 'vuex'
import { $upload } from "@/utils/request"

export default {
  name: 'Portrait',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
    [Uploader.name]: Uploader
  },
  data() {
    return {
      DataArr: {
        url: '',
        icon: '', // 传递给后台的
      },
      ImgArr: ImgData, // img图片资源
      isUpload: false
      // userInfo: {}
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {
    userInfo(val) {
      if (val.userimg) {
        this.DataArr.url = this.$img(val.userimg);
        this.DataArr.icon = val.userimg;
      }
    }
  },
  methods: {
    // getUserInfo(){
    //   this.userInfo = JSON.parse(this.$cookie.get('userInfo'));
    // },
    async upload(e) {
      const file = e.target.files[0];
      Toast.loading({ duration: 0 });
      try {
        const { data: { ret, msg, data } } = await $upload({ image: file });
        Toast.clear();
        console.log(data);
        if (ret === 1) {
          this.DataArr.icon = data.path;
          this.DataArr.url = this.$img(data.path);
          this.imgFile = [{ url: this.$img(data.path), isImage: true }]
          this.isUpload = true;
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
    imgUrlToFile(url) {
      console.log(url);
      var self = this;
      var imgLink = url;
      var tempImage = new Image();
      // tempImage.onload = function () {
      //   var base64 = self.getBase64Image(tempImage);
      //   var imgblob = self.base64toBlob(base64);
      //   var filename = self.getFileName(self.ruleForm.coverUrl);
      //   self.ruleForm.coverFile = self.blobToFile(imgblob, filename);
      // };
      // tempImage.src = imgLink;
    },
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    // 图片的点击事件
    ImgEvent(url) {
      this.isUpload = false;
      this.DataArr.icon = url
      this.DataArr.url = this.$img(url)
      this.imgFile = [{ url: url, isImage: true }]
    },
    async SubmitEvent() {
      if(this.isUpload) return this.$router.go(-1);
      Toast.loading({duration: true});
      try {
        const { data: {ret, msg, data} } = await $upload({ userimg: this.DataArr.icon });
        Toast.clear();
        if(ret === 1){
          this.$store.dispatch("getUserInfo");
          return this.$router.go(-1);
        }
        Toast(msg)
      } catch (error) {
        Toast.clear();
        console.warn(error);
      }
    },
    async getImgObject(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const render = new FileReader();
        render.onload = (e) => {
          img.src = e.target.result;
        }
        render.onerror = (err) => {
          reject(err);
        }
        render.readAsDataURL(file);
        img.onload = () => {
          resolve(img)
        }
        img.onerror = (err) => {
          reject(err)
        }
      })
    }
  },
  created() {
    // this.getUserInfo();
    this.$store.dispatch('getUserInfo');
    if (this.userInfo.userimg) {
      this.DataArr.url = this.$img(this.userInfo.userimg);
      this.DataArr.icon = this.userInfo.userimg;
    }
  },
}
</script>

<style lang="less">
#Portrait {
  width: 100%;
  height: 100%;

  .header-One {
    opacity: 0;
    width: 100%;
    height: 7.8rem;
  }

  .header {
    position: fixed;
    width: 100%;
    height: 7.8rem;
    z-index: 1;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .upload_wrap {
      width: 3.75rem;
      height: 3.75rem;
      position: relative;

      >input {
        position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }

    img {
      border-radius: 50px;
    }

    .van-image {
      margin-bottom: 0.7rem;
    }

    >.button {
      padding: 0.25rem 1.3rem;
      border: 1px solid #fff;
      border-radius: 0.35rem;
      color: #fff;
      font-size: 0.875rem;
    }
  }

  .content {
    border-top: 0.625rem solid #f2f2f2;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    >.content-item {
      width: 20%;
      height: 3rem;
      padding: 0.625rem 0;

      >div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 3rem;
        border-radius: 50px;
      }
    }
  }
}
</style>